<template>
  <vue-final-modal class="modal-loyalty-details">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.bonusDetailsTitle') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('loyalty-cashback-details')" />
      </div>
    </div>

    <div class="vfm__body">
      <card-cashback-bonus :bonusInfo="props.bonusInfo" hideButton />

      <div
        class="modal-loyalty-details__subtitle"
        v-html="DOMPurify.sanitize(marked.parseInline(modalSubtitle) as string, { FORBID_TAGS: ['style'] })"
      />

      <div class="modal-loyalty-details__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <div class="modal-loyalty-details__list-label">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cashbackListLabel') }}
      </div>

      <loyalty-cashback :bonusId="props.bonusInfo.bonusId" />

      <div
        v-router-links
        class="modal-loyalty-details__more-info"
        @click="clickMoreInfoElement"
        v-html="DOMPurify.sanitize(marked.parseInline(moreInfo) as string, { FORBID_TAGS: ['style'] })"
      />

      <div class="modal-loyalty-details__actions">
        <button-base
          class="modal-loyalty-details__button"
          type="secondary-2"
          size="md"
          @click="closeModal('loyalty-cashback-details')"
        >
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'buttonLabel') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { marked } from 'marked';
  import DOMPurify from 'isomorphic-dompurify';
  import { VueFinalModal } from 'vue-final-modal';
  import type { CILoyaltyCashbackDetailsModal, IPlayerCashback } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CILoyaltyCashbackDetailsModal>;
    defaultLocaleData: Maybe<CILoyaltyCashbackDetailsModal>;
    bonusInfo: IPlayerCashback;
  }>();

  const { getContent, replaceContent } = useProjectMethods();
  const { closeModal } = useModalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();

  const modalSubtitle = computed(() => {
    const subtitleContent = getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') || '';

    return replaceContent(subtitleContent, '*');
  });

  const moreInfo = computed(() => getContent(props.currentLocaleData, props.defaultLocaleData, 'moreInfoText') || '');

  const clickMoreInfoElement = (event: any): void => {
    const targetElement = event.target as HTMLElement;
    if (targetElement.tagName === 'A') closeModal('loyalty-cashback-details');
  };
</script>

<style src="~/assets/styles/components/modal/loyalty-details.scss" lang="scss" />
