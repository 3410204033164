<template>
  <div class="loyalty-details">
    <div v-if="currentCashbackBonus" class="loyalty-details__active loyalty-details__row">
      <div class="loyalty-details__active-border-overlay" />
      <div class="loyalty-details__active-bg-overlay" />

      <loyalty-tag v-bind="currentCashbackBonus.tagData" />
      <span class="loyalty-details__row-name">{{ currentCashbackBonus.name }}</span>
      <span class="loyalty-details__active-label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.currentLevelLabel') }}
      </span>
      <span class="loyalty-details__row-value">{{ currentCashbackBonus.cashback }}</span>
    </div>

    <div v-for="bonus in nextCashbackBonuses" :key="bonus.order" class="loyalty-details__row">
      <loyalty-tag v-bind="bonus.tagData" />
      <span class="loyalty-details__row-name">{{ bonus.name }}</span>
      <span class="loyalty-details__row-value">{{ bonus.cashback }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps<{
    bonusId: string;
  }>();

  const { getContent, levelNameWithoutNumber } = useProjectMethods();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const loyaltyStore = useLoyaltyStore();
  const { loyaltyLevels, loyaltyAccount } = storeToRefs(loyaltyStore);

  const cashbackListContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.loyalty?.cashbackList
    : globalComponentsContent?.loyalty?.cashbackList || [];

  const cashbackTagsContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.loyalty?.tags
    : globalComponentsContent?.loyalty?.tags || [];

  const currentCashbackBonus = computed(() => {
    if (!loyaltyLevels.value.length || !loyaltyAccount.value || !cashbackListContent.length) return;

    const cashbackContentData = cashbackListContent.find(cashbackContent => {
      return (
        cashbackContent.loyaltyLevels.includes(String(loyaltyAccount.value?.currentLevel.order)) &&
        cashbackContent.bonusId === props.bonusId
      );
    });

    const tagData = cashbackTagsContent.find(cashbackTag =>
      cashbackTag.loyaltyLevels.includes(String(loyaltyAccount.value?.currentLevel.order))
    );

    return {
      name: levelNameWithoutNumber(loyaltyAccount.value.currentLevel.name),
      cashback: cashbackContentData?.cashbackValue,
      tagData: tagData,
    };
  });

  const nextCashbackBonuses = computed(() => {
    if (!loyaltyLevels.value.length || !loyaltyAccount.value || !cashbackListContent.length) return [];

    const list: {
      order: number;
      name: string;
      tagData: { topColor?: string; bottomColor?: string };
      cashback: string;
    }[] = [];

    loyaltyLevels.value.forEach(levelData => {
      if (levelData.order <= (loyaltyAccount.value?.currentLevel.order ?? 100)) return;

      const cashbackContentData = cashbackListContent.find(cashbackContent =>
        cashbackContent.loyaltyLevels.includes(String(levelData.order))
      );

      if (
        currentCashbackBonus.value?.cashback === cashbackContentData?.cashbackValue ||
        list.some(listItem => listItem.cashback === cashbackContentData?.cashbackValue)
      )
        return;

      const tagContentData = cashbackTagsContent.find(cashbackTag =>
        cashbackTag.loyaltyLevels.includes(String(levelData.order))
      );

      list.push({
        order: levelData.order,
        name: levelNameWithoutNumber(levelData.name),
        tagData: { topColor: tagContentData?.topColor, bottomColor: tagContentData?.bottomColor },
        cashback: cashbackContentData?.cashbackValue || '',
      });
    });

    return list;
  });
</script>

<style src="~/assets/styles/components/loyalty/details.scss" lang="scss" />
